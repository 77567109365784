// General
import React from "react";
import {
  IncidentStep,
  incidentStepIconNames,
  incidentStepType,
  InfoFieldTypes,
} from "compass-commons";
import { ResourceIcon } from "dms-lib";
// Constants
import { incidentStepIconNamesMap } from "../../../../util/Constants";

const IncidentStepIcon = (props: {
  incidentStep: IncidentStep;
  baseColor: string;
  isSystemStep: boolean;
  onClick: (Event) => void;
}): JSX.Element => {
  const { incidentStep, baseColor, isSystemStep, onClick } = props;

  const hasOnlyPhotos = (): boolean => {
    return incidentStep.genericInfoList?.every(
      ({ infoFieldType }) => infoFieldType === InfoFieldTypes.PHOTO
    );
  };

  const getIncidentStepIcon = (
    iconName: string
  ): React.ComponentType | null => {
    if (incidentStep?.icon) {
      return () => <ResourceIcon src={incidentStep.icon} />;
    }

    return incidentStepIconNamesMap[iconName] || null;
  };

  const defaultSystemIcon = (children?) => {
    return (
      <div
        className="timeline-dot"
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: children ? `${baseColor}` : "white",
          boxShadow: `0 0 0 8px ${baseColor}`,
        }}
      >
        {children}
      </div>
    );
  };

  const defaultOperatorIcon = (
    <div
      className="timeline-dot"
      style={{
        width: "20px",
        height: "20px",
        backgroundColor: "white",
        transform: "translateY(var(--msi-ui-spacing-xs))",
      }}
    />
  );

  const getDefaultIcon = () => {
    if (isSystemStep) {
      return defaultSystemIcon();
    }
    return defaultOperatorIcon;
  };

  const getDarkGreyIcon = (iconName: string) => {
    const Icon = getIncidentStepIcon(iconName);
    return Icon ? (
      <div
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
        className="timeline-dot-darkblue compass-box-shadow"
      >
        <Icon />
      </div>
    ) : null;
  };

  const GenericIcon = () => {
    let Icon: React.ComponentType | null;
    if (!incidentStep?.icon && hasOnlyPhotos()) {
      Icon = getIncidentStepIcon(incidentStepIconNames.SNAP);
    } else {
      Icon = getIncidentStepIcon(incidentStepIconNames.GENERIC_INFO);
    }
    return (
      <div
        className="generic-icon timeline-dot"
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <Icon />
      </div>
    );
  };

  const getManualIcon = () => {
    if (
      incidentStep.genericInfoList.filter(
        (info) => info.infoFieldType === InfoFieldTypes.PHOTO
      ).length > 0
    ) {
      return <GenericIcon />;
    }

    return isSystemStep ? (
      <>
        {defaultSystemIcon(
          <div
            className="timeline-dot"
            style={{
              width: "30px",
              height: "30px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "20px",
                height: "10px",
              }}
            />
          </div>
        )}
      </>
    ) : (
      <div
        className="timeline-dot"
        style={{
          width: "30px",
          height: "30px",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "20px",
            height: "10px",
          }}
        />
      </div>
    );
  };

  const getIcon = (iconName: string) => {
    const Icon = getIncidentStepIcon(iconName);
    const IconDiv = Icon ? (
      <div className="timeline-dot" style={{ width: "30px", height: "30px" }}>
        <Icon />
      </div>
    ) : null;
    return isSystemStep ? <>{defaultSystemIcon(<Icon />)}</> : IconDiv;
  };

  const getClearIcon = () => {
    const Icon = getIncidentStepIcon(incidentStepIconNames.CLR);
    return (
      <div
        className="timeline-dot "
        style={{
          backgroundColor: "var(--iconGreyColor)",
          width: "30px",
          height: "30px",
        }}
      >
        <Icon />
      </div>
    );
  };

  const getIncidentDataElements = () => {
    if (incidentStep?.type) {
      switch (incidentStep.type.toLowerCase()) {
        case incidentStepType.POST:
        case incidentStepType.SNAP: {
          return getDarkGreyIcon(incidentStepIconNames.SNAP);
        }
        case incidentStepType.AUDIO: {
          return getDarkGreyIcon(incidentStepIconNames.AUDIO);
        }
        case incidentStepType.VIDEO: {
          return getDarkGreyIcon(incidentStepIconNames.VIDEO);
        }
        case incidentStepType.ATTACH: {
          return getDarkGreyIcon(incidentStepIconNames.ATTACH);
        }
        case incidentStepType.MANUAL_TASK: {
          return getManualIcon();
        }
        case incidentStepType.COMMENT: {
          return getIcon(incidentStepIconNames.COMMENT);
        }
        case incidentStepType.CALL: {
          return getIcon(incidentStepIconNames.CALL);
        }
        case incidentStepType.CONTACT: {
          return getIcon(incidentStepIconNames.CONTACT);
        }
        case incidentStepType.CLR: {
          return getClearIcon();
        }
        case incidentStepType.ACTIVATIONS_GROUP: {
          return getIcon(incidentStepIconNames.ACTIVATIONS_GROUP);
        }
        case incidentStepType.GENERIC_INFO: {
          return <GenericIcon />;
        }
        default: {
          return <>{getDefaultIcon()}</>;
        }
      }
    }
    return <></>;
  };

  return <>{incidentStep && <>{getIncidentDataElements()}</>}</>;
};

export default IncidentStepIcon;
