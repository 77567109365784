import { Button } from "dms-lib";
import React from "react";
import { NoteAddRounded } from "@mui/icons-material";
import "./addToReportButton.module.css";

interface AddToReportButtonProps {
  onClick: () => void;
}

const AddToReportButton = ({
  onClick,
}: AddToReportButtonProps): JSX.Element => {
  return (
    <Button
      color="primary"
      variant="contained"
      className="add-to-report-button"
      data-cy="add-to-report"
      onClick={onClick}
    >
      Add to report
      <NoteAddRounded />
    </Button>
  );
};

export default AddToReportButton;
