// General
import React, { useEffect, useState } from "react";
import {
  LocalizationNS,
  ReportCart,
  ReportCartStateEnum,
  useI18n,
} from "compass-commons";
// Components
import { Button, IAlertNotification, IncidentIcon } from "dms-lib";
import DescriptionRounded from "@mui/icons-material/DescriptionRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import AutoGraphRoundedIcon from "@mui/icons-material/AutoGraphRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Badge from "@mui/material/Badge";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Divider from "@mui/material/Divider";
import { Alert, Fab } from "@mui/material";
// Styles
import "./ReportCartBadge.css";
// Store
import { BehaviorSubject } from "rxjs";
// Services
import { useMutation } from "@tanstack/react-query";
import ReportCartService from "../../services/ReportCartService";
// Errors
import ReportCartGetCartsError from "../../errors/ReportCartGetCartsError";

interface ReportCartBadgeProps {
  reportCartSubject: BehaviorSubject<ReportCart>;
  badgeErrorMessageSubject: BehaviorSubject<string>;
  alertSubject: BehaviorSubject<IAlertNotification>;
  onClickCallBack: (isClicked: boolean) => void;
}

interface DeleteItemProps {
  cart: ReportCart;
  itemIndex: number;
}

const ReportCartBadge = (props: ReportCartBadgeProps): JSX.Element => {
  const {
    reportCartSubject,
    badgeErrorMessageSubject,
    onClickCallBack,
    alertSubject,
  } = props;
  const { t: translate } = useI18n();
  const [reportItemsCount, setReportItemsCount] = useState(
    reportCartSubject?.value?.elementList?.length || 0
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [errorMessage, setErrorMessage] = useState(
    badgeErrorMessageSubject?.value
  );
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [currentDeleteIndex, setCurrentDeleteIndex] = useState(null);
  const cartDeleteMutation = useMutation({
    mutationFn: ({ cart, itemIndex }: DeleteItemProps) => {
      setCurrentDeleteIndex(itemIndex);
      return ReportCartService.removeCartItem(cart, itemIndex);
    },
    onSuccess: (res) => {
      reportCartSubject.next(res);
      setCurrentDeleteIndex(null);
    },
    onError: () => {
      setCurrentDeleteIndex(null);
      alertSubject.next({
        title: translate("reportCart.deleteItemError"),
        severity: "error",
      });
    },
  });

  useEffect(() => {
    ReportCartService.getCarts(ReportCartStateEnum.ACTIVE)
      .then((res) => {
        if (res && res.length > 0) {
          reportCartSubject.next(res[0]); // TODO there is only one active in the cart list. But this may change in the future.
          setReportItemsCount(res[0]?.getElementCount());
        } else {
          reportCartSubject.next(null);
          setReportItemsCount(0);
        }
      })
      .catch((e) => {
        throw new ReportCartGetCartsError(e);
      });

    const subscription = reportCartSubject?.subscribe((value) => {
      if (value) {
        setReportItemsCount(value.getElementCount());
      }
    });

    const subsBadgeError = badgeErrorMessageSubject?.subscribe((value) => {
      if (value) {
        setErrorMessage(value);
        setAlertIsOpen(true);
        setTimeout(() => {
          setAlertIsOpen(false);
          badgeErrorMessageSubject.next(null);
        }, 4000);
      }
    });

    return function cleanup() {
      subscription?.unsubscribe();
      subsBadgeError?.unsubscribe();
    };
  }, [reportCartSubject, badgeErrorMessageSubject, errorMessage]);

  const handlePopperClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const getPopperItems = () => {
    const items: JSX.Element[] = [];
    let tableCount = 0;
    reportCartSubject?.value?.elementList?.forEach((el, itemIndex) => {
      if (el.type === "CRITERIA") tableCount += 1;

      const div: JSX.Element = (
        <>
          <div
            className="report-cart-item"
            // eslint-disable-next-line react/no-array-index-key
            key={`cart-item-${itemIndex}`}
            style={{
              opacity: currentDeleteIndex === itemIndex ? 0.2 : 1,
            }}
          >
            {el.detailCriteria?.incidentDescription ? (
              <>
                <IncidentIcon
                  src={el.detailCriteria.icon}
                  priority={el.detailCriteria.priority}
                  size="small"
                />
                <span className="report-cart-item-text">
                  {el.detailCriteria.incidentDescription}
                </span>
              </>
            ) : (
              <>
                {el.filteringCriteria ? (
                  <AutoGraphRoundedIcon />
                ) : (
                  <FormatListBulletedRoundedIcon />
                )}
                <span className="report-cart-item-text">
                  {el.filteringCriteria
                    ? translate("reportCart.resultsTable", {
                        tableNumber: tableCount,
                      })
                    : el.type}
                </span>
              </>
            )}
            <Button
              variant="text"
              color="inherit"
              icon
              onClick={() =>
                cartDeleteMutation.mutate({
                  cart: reportCartSubject.value,
                  itemIndex,
                })
              }
              disabled={cartDeleteMutation.isLoading}
            >
              <CloseRoundedIcon />
            </Button>
          </div>
          <Divider className="report-cart-item-divider" />
        </>
      );
      items.push(div);
    });

    return items;
  };

  return (
    <div style={{ display: "flex" }}>
      {alertIsOpen ? (
        <Alert
          style={{ marginRight: "5px" }}
          className="dms-cart-alert-message"
          variant="outlined"
          severity="info"
        >
          {errorMessage}
        </Alert>
      ) : (
        <></>
      )}
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Fade {...TransitionProps} timeout={350}>
            <div
              className="cart-popper compass-rounded-corner compass-box-shadow"
              data-cy="cart-popper"
            >
              {reportItemsCount > 0 ? (
                <>
                  <div className="cart-header">
                    <span className="cart-header-text">
                      {translate("reportCart.title")}
                    </span>
                  </div>
                  <div className="cart-list-scroll">{getPopperItems()}</div>
                  <div className="cart-footer">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onClickCallBack(true)}
                    >
                      {translate("reportCart.goToReport")}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <span>{translate("reportCart.emptyCart")}</span>
                  <div className="cart-footer">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpen(false)}
                    >
                      {translate("close", { ns: LocalizationNS.SHARED })}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Fade>
        )}
      </Popper>
      <div aria-label="cart badge">
        <Badge color="error" overlap="circular" badgeContent={reportItemsCount}>
          <Fab
            color="primary"
            size="small"
            onClick={handlePopperClick("bottom-end")}
            className="report-cart-badge-fab"
            data-cy="report-cart-button"
          >
            <DescriptionRounded />
          </Fab>
        </Badge>
      </div>
    </div>
  );
};

export default ReportCartBadge;
