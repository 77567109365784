// General
import React, { useEffect, useState } from "react";
import {
  getBaseColorVar,
  Incident,
  ReportCartItem,
  ReportCartItemTypeEnum,
  sessionStorageIdentifiers,
  useGetOrganization,
  useI18n,
} from "compass-commons";
// Components
import Spinner from "@msi/cobalt-react/spinner";
import VerticalTimeline from "./verticalTimeline/VerticalTimeline";
// Styles
import "./detailsPanel.module.css";
// Store
import { useStateContext } from "../../contexts/StateContext";
// Services
import DataExplorerService from "../../services/DataExplorerService";
import StateService from "../../services/StateService";
import ReportCartService from "../../services/ReportCartService";
// Models
import { StateHolder } from "../../models/state/StateHolder";
import AddToReportButton from "../commons/addToReportButton/AddToReportButton";
// Errors
import ReportCartItemAlreadyExistsError from "../../errors/ReportCartItemAlreadyExistsError";

const DetailsPanel = (): JSX.Element => {
  const stateService: StateService = useStateContext();
  const { t: translate } = useI18n();
  const organizationId = useGetOrganization();
  const {
    selectedIncidentId,
    alertSubject,
    currentReportCart,
    badgeAlreadyHasSelectedItemMessage,
  } = stateService;
  const [incidentId, setIncidentId] = useState(selectedIncidentId.value);
  const [dataFromApi, setDataFromApi] = useState(null);
  const [loading, setLoading] = useState(false);

  if (sessionStorage.getItem(sessionStorageIdentifiers.STATE_HOLDER)) {
    const stateHolder = JSON.parse(
      sessionStorage.getItem(sessionStorageIdentifiers.STATE_HOLDER)
    ) as StateHolder;
    if (stateHolder.selectedIncident && stateHolder.org === organizationId) {
      stateService.selectedIncident.next(stateHolder.selectedIncident);
      selectedIncidentId.next(stateHolder.selectedIncident.id);
    }
    stateHolder.selectedIncident = null;
    sessionStorage.setItem(
      sessionStorageIdentifiers.STATE_HOLDER,
      JSON.stringify(stateHolder)
    );
  }

  useEffect(() => {
    setIncidentId(selectedIncidentId.value);
    const incidentSubject = stateService.selectedIncident;

    function getIncident(id) {
      setLoading(true);
      DataExplorerService.getIncidentById(id)
        .then((incident: Incident) => {
          setDataFromApi(incident);
          incidentSubject.next(incident);
          setLoading(false);
        })
        .catch(() => {
          alertSubject.next({
            title: translate("details.failedIncidentDetails"),
          });
          setDataFromApi(null);
          setLoading(false);
        });
    }

    const subscription = selectedIncidentId.subscribe((value) => {
      if (value === null) {
        setDataFromApi(null);
        setIncidentId(null);
      }
      if (value != null && value !== incidentId) {
        setIncidentId(value);
        getIncident(value);
      }
    });

    if (incidentId != null) {
      getIncident(incidentId);
    }

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [stateService, incidentId, selectedIncidentId]);

  const addToReport = () => {
    const currentCart = currentReportCart.value;
    const reportCartItem = new ReportCartItem();
    reportCartItem.type = ReportCartItemTypeEnum.DETAIL;
    reportCartItem.detailCriteria = {
      incidentId,
      priority: dataFromApi.priority,
      incidentDescription: dataFromApi.incidentDescription,
      icon: dataFromApi.icon,
      type: dataFromApi.type,
    };

    ReportCartService.addCartItem(currentCart, reportCartItem)
      .then((res) => currentReportCart.next(res))
      .catch((error) => {
        if (error instanceof ReportCartItemAlreadyExistsError) {
          badgeAlreadyHasSelectedItemMessage.next(error.message);
        } else {
          alertSubject.next({
            title: translate("details.failedToAddReport"),
          });
        }
      });
  };

  return (
    <div className="detail-container">
      <div className="detail-content">
        <div className="details-widget-main" data-cr="details-panel-main">
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              data-cr="details-panel-spinner"
            >
              <Spinner size="large" />
            </div>
          ) : (
            <VerticalTimeline
              incident={dataFromApi}
              baseColor={
                dataFromApi != null ? getBaseColorVar(dataFromApi.priority) : ""
              }
            />
          )}
        </div>
      </div>
      {incidentId !== null && (
        <div className="detail-footer">
          <AddToReportButton onClick={addToReport} />
        </div>
      )}
    </div>
  );
};

export default DetailsPanel;
